import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import * as _ from 'lodash'

const PreloadPosts = (props) => {
    const { data } = props
    const allPosts = data.allMarkdownRemark.edges

    //lodash
    const posts = _.sampleSize(allPosts, 3)
    return (
        <div className="columns">
            {posts.map(({ node: post }) => (
                <div className="column" key={post.id}>
                    <Link to={post.fields.slug}>
                        <div className="card box cardPreview">
                            <div className="card-image">
                                {post.frontmatter.featuredimage ? (
                                    <PreviewCompatibleImage
                                        imageInfo={{
                                            image: post.frontmatter
                                                .featuredimage,
                                            alt: `${post.frontmatter.title}`,
                                        }}
                                    />
                                ) : null}
                            </div>
                            <br />
                            <div className="content">
                                <Link to={post.fields.slug}>
                                    <div className="titlePrev">
                                        <p className="title is-size-5 BrandonGrotesqueRegular titlePrevIos">
                                            {post.frontmatter.title}
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    )
}

export default () => (
    <StaticQuery
        query={graphql`
            query PreloadPostsQuery {
                allMarkdownRemark(
                    filter: {
                        frontmatter: { templateKey: { eq: "blog-post" } }
                    }
                ) {
                    edges {
                        node {
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                templateKey
                                featuredimage {
                                    childImageSharp {
                                        gatsbyImageData(
                                            quality: 100
                                            formats: WEBP
                                            layout: FULL_WIDTH
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data, count) => <PreloadPosts data={data} count={count} />}
    />
)

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { SEO } from "../components/SEO";

export const Head = ({data}) => {
  const { markdownRemark: post } = data

  return (
    <SEO
      title={post.frontmatter.title}
      description={post.frontmatter.description}
      image={post.frontmatter.featuredimage.childImageSharp.original.src}
      pathname={post.fields.slug}
    />
  )
}

Head.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}



import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import hljs from 'highlight.js'
import 'highlight.js/styles/base16/harmonic16-light.css'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    FacebookIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
} from 'react-share'
import PreloadPosts from '../components/PreloadPosts'

export const BlogPostTemplate = ({
    content,
    contentComponent,
    title,
    featuredimage,
}) => {
    const PostContent = contentComponent || Content

    const updateCodeSyntaxHighlighting = () => {
        document.querySelectorAll('code').forEach((block) => {
            hljs.configure({ ignoreUnescapedHTML: true })
            if (block.className) {
                hljs.highlightElement(block)
            } else {
                block.classList.add('text')
            }
        })
    }

    useEffect(() => {
        updateCodeSyntaxHighlighting()
    })

    const [currentRoute, setCurrentRoute] = useState(false)

    useEffect(() => setCurrentRoute(document.location.href), [])
    return (
        <section
            className="section brandonRegular"
            style={{ padding: '.8rem 3rem' }}
        >
            <div className="container is-max-widescreen brandonRegular">
                <div className="content">
                    <div style={{ marginBottom: '1em' }}>
                        <h1 className="is-size-1 is-size-2-title-mobile BrandonGrotesqueRegular title">
                            {title}
                        </h1>
                    </div>
                    <div>
                        <div className="featured-thumbnail">
                            <PreviewCompatibleImage
                                imageInfo={{
                                    image: featuredimage,
                                    alt: `featured image thumbnail for post ${title}`,
                                }}
                            />
                        </div>
                        <div
                            className="is-12 is-offset-1 is-size-5 is-size-6-mobile"
                            style={{ marginTop: '2em' }}
                        >
                            <PostContent content={content}>
                                <h2 className="is-size-2">{}</h2>
                                <h3 className="title is-size-4">{}</h3>
                            </PostContent>
                            <div
                                className="has-text-right"
                                style={{
                                    marginTop: '.5em',
                                    textAlign: 'right',
                                }}
                            >
                                <FacebookShareButton url={currentRoute}>
                                    <FacebookIcon
                                        size={40}
                                        borderRadius="20px"
                                        round={true}
                                        style={{ marginLeft: '.5em' }}
                                    />
                                </FacebookShareButton>
                                <LinkedinShareButton url={currentRoute}>
                                    <LinkedinIcon
                                        size={40}
                                        borderRadius="20px"
                                        round={true}
                                        style={{ marginLeft: '.5em' }}
                                    />
                                </LinkedinShareButton>
                                <TelegramShareButton url={currentRoute}>
                                    <TelegramIcon
                                        size={40}
                                        borderRadius="20px"
                                        round={true}
                                        style={{ marginLeft: '.5em' }}
                                    />
                                </TelegramShareButton>
                                <TwitterShareButton url={currentRoute}>
                                    <TwitterIcon
                                        size={40}
                                        borderRadius="20px"
                                        round={true}
                                        style={{ marginLeft: '.5em' }}
                                    />
                                </TwitterShareButton>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '2.5em' }}>
                        <PreloadPosts />
                    </div>
                </div>
            </div>
        </section>
    )
}

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    featuredimage: PropTypes.node.isRequired,
    date: PropTypes.string,
}

const BlogPost = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout>
            <BlogPostTemplate
                date={post.frontmatter.date}
                featuredimage={post.frontmatter.featuredimage}
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                title={post.frontmatter.title}
            />
        </Layout>
    )
}

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}

export default BlogPost

export const pageQuery = graphql`
    query BlogPostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                description
                featuredimage {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            formats: WEBP
                            layout: FULL_WIDTH
                        )
                        original {
                            width
                            height
                            src
                        }
                    }
                }
            }
            fields {
                slug
            }
        }
    }
`

export { Head } from './Head'
